<template>
    <v-container  class="pa-0">
        <v-card>
            <v-card-title>
                Tareas

                <v-spacer></v-spacer>

                 <v-progress-circular  :value="progress" class="mr-2" v-if="!loading">    
                        <template v-slot:default>
                            <strong style="font-size: 8px;">{{ progress && progress.toFixed(0) || 0 }}%</strong>
                        </template>
                    </v-progress-circular>
                    <v-progress-circular indeterminate class="mr-2" v-else></v-progress-circular>

            </v-card-title>
            <v-card-text>
                 <v-divider ></v-divider>
             <v-text-field v-model="newTask" label="Agregar tarea.." filled  @keydown.enter="create" dense >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-icon v-if="newTask" @click="create">
                                    mdi-plus-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>


            <v-row class="my-1" align="center">
                <v-btn class="mx-4 warning--text text--darken-2" @click="view=true" >
                    Pendientes: {{ remainingTasks }}
                </v-btn>

                <v-divider vertical></v-divider>

                <v-btn class="mx-4 success--text text--darken-2" @click="view=false">
                    Completadas: {{ completedTasks }}
                </v-btn>

                <v-spacer></v-spacer>

               
            </v-row>

            <v-divider class=""></v-divider>

            <v-card v-if="tasks.length > 0">
                <v-slide-y-transition class="py-0" group  v-if="returnTasks.length > 0">
                    <template v-for="(task, i) in returnTasks">
                        <v-divider v-if="i !== 0" :key="`${i}-divider`" class="my-0
                        "></v-divider>

                        <v-list-item :key="`${i}-${task.text}`">
                            <v-list-item-action>
                                <v-checkbox v-model="task.done" :color="task.done && 'grey' || 'primary'" @click="toggleTaskStatus(task)">
                                  
                                </v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content v-text="task.text" :class="task.done && 'grey--text' || 'primary--text'">
                       
                            </v-list-item-content>

                            <v-scroll-x-transition>

                                <!--
                                    assignees chips
                                -->
                                <v-chip v-for="(assignee, i) in task.assignees" :key="`${i}-${assignee}`" class="mx-1" small>
                                    {{ assignee }}
                                </v-chip>


                                <v-icon v-if="task.done" color="success">
                                    mdi-check
                                </v-icon>
                                <v-btn v-else fab icon x-small color="error" class="ml-2" @click="deleteTask(task.id)">
                                   <v-icon> mdi-close</v-icon>
                                </v-btn>


                            </v-scroll-x-transition>
                        </v-list-item>
                    </template>
                </v-slide-y-transition>
                <v-slide-y-transition class="py-0" group v-else>
                    <v-list-item :key="'no-result-id'">
                        <v-list-item-content>
                            <v-list-item-title class="text-center">
                                No hay tareas {{ view && 'pendientes' || 'completadas' }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-slide-y-transition>
            


            </v-card>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
// Import the Firebase utilities
import { getFirestore, collection, addDoc, query, onSnapshot, doc, updateDoc, deleteDoc } from 'firebase/firestore';

export default {
    data() {
        return {
            newTask: null,
            tasks: [],
            loading: true,
            // true to show only not done tasks and false to show only done tasks
            view: true
        }
    },

    computed: {
        completedTasks() {
            return this.tasks.filter(task => task.done).length
        },
        progress() {
            return this.completedTasks / this.tasks.length * 100
        },
        remainingTasks() {
            return this.tasks.length - this.completedTasks
        },
        returnTasks() {
            if (this.view) {
                return this.tasks.filter(task => !task.done)
            } else {
                return this.tasks.filter(task => task.done)
            }
            return 
        }
    },

    methods: {
        async create() {
            if (!this.newTask) return;
            try {
                const db = getFirestore();

                await addDoc(collection(db, "tasks"), {
                    text: this.newTask,
                    done: false,
                    date: new Date(),
                    assignees: [],
                    createdBy: this.$store.state.Auth.token.claims.user_id,
                    completedBy: null
                });
                this.newTask = null;
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        },
        async toggleTaskStatus(task) {
            const db = getFirestore();

            const taskRef = doc(db, "tasks", task.id);
            try {
                await updateDoc(taskRef, {
                    done: task.done,
                    completedBy: task.done && null || this.$store.state.Auth.token.claims.user_id
                });
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        },
        async deleteTask(id) {

            const confirm = await this.$confirm('¿Realmente quieres eliminar esta tarea?',
                {
                    color: "error",
                    title: "Borrar tarea",
                    buttonTrueText: "CONFIRMAR",
                    buttonFalseText: "CANCELAR"
                }
            );

            if (!confirm) return;

            const db = getFirestore();

            const taskRef = doc(db, "tasks", id);
            try {
                await deleteDoc(taskRef);
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        }
    },

    mounted() {
        const db = getFirestore();

        const q = query(collection(db, "tasks"));
        onSnapshot(q, (querySnapshot) => {
            this.tasks = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            this.loading = false;
        });
    },
}
</script>
